import useTranslation from 'next-translate/useTranslation';
import { formatCurrencyWithDecimal } from '@/utils/helpers/formatCurrency';
import Discount from '../icons/Discount';
import Paragraph, { VARIANT } from '../Paragraph';
interface Props {
  couponAmount: number;
  isInsuranceAdded: boolean;
  shippingCharges?: number;
  totalDiscount: number;
  totalInsuranceCost: number;
  showDiscountMsg?: boolean;
  totalVariantCost: number;
  couponCode?: string;
}
const PriceDetail = ({
  couponAmount,
  isInsuranceAdded,
  shippingCharges,
  totalDiscount,
  totalInsuranceCost,
  totalVariantCost,
  showDiscountMsg = true,
  couponCode
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const isShippingFree = shippingCharges == 0;
  const isInsuranceFree = totalInsuranceCost == 0;
  return <section className="bg-white p-4 rounded-xl" data-sentry-component="PriceDetail" data-sentry-source-file="PriceDetail.tsx">
      <div>
        <p className="font-nato-semibold text-lg text-neutral-90">
          {t('bill_details')}
        </p>
        <div className="flex justify-between my-2">
          <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-80" data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {t('item_total')}
          </Paragraph>
          <Paragraph variant={VARIANT.MEDIUM} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {formatCurrencyWithDecimal(totalVariantCost, 2)}
          </Paragraph>
        </div>
        {totalDiscount ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-80">
              {t('total_discount')}
            </Paragraph>
            <Paragraph variant={VARIANT.MEDIUM} isHighlighted>
              {`- ${formatCurrencyWithDecimal(totalDiscount, 2)}`}
            </Paragraph>
          </div> : null}
        {couponAmount ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-80">
              {t('your_coupon')}
              {couponCode && <span className="text-primary-70">{` (${couponCode})`}</span>}
            </Paragraph>
            <Paragraph variant={VARIANT.MEDIUM} isHighlighted className="text-primary-80">
              - {formatCurrencyWithDecimal(couponAmount, 2)}
            </Paragraph>
          </div> : null}
        <div className="flex justify-between my-2">
          <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-80" data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {t('delivery_charges')}
          </Paragraph>
          <Paragraph variant={VARIANT.MEDIUM} isHighlighted className={`${isShippingFree ? 'text-primary-80' : ''}`} data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {isShippingFree ? t('free') : formatCurrencyWithDecimal(shippingCharges || '', 2)}
          </Paragraph>
        </div>
        {isInsuranceAdded ? <div className="flex justify-between my-2">
            <Paragraph variant={VARIANT.MEDIUM} className={`text-neutral-80 `}>
              {t('insurance_charges')}
            </Paragraph>
            <Paragraph variant={VARIANT.MEDIUM} isHighlighted className={`${isInsuranceFree ? 'text-primary-80' : ''}`}>
              {isInsuranceFree ? t('free') : formatCurrencyWithDecimal(totalInsuranceCost, 2)}
            </Paragraph>
          </div> : null}
      </div>
      <hr className="my-4" />
      <div>
        <div className="flex justify-between">
          <Paragraph variant={VARIANT.MEDIUM} className="font-nato-semibold text-neutral-80" data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {t('total_cost')}
          </Paragraph>
          <Paragraph variant={VARIANT.MEDIUM} isHighlighted data-sentry-element="Paragraph" data-sentry-source-file="PriceDetail.tsx">
            {formatCurrencyWithDecimal(totalVariantCost + (isInsuranceAdded ? totalInsuranceCost : 0) - totalDiscount + (shippingCharges || 0.0) - couponAmount, 2)}
          </Paragraph>
        </div>
        {showDiscountMsg && totalDiscount + couponAmount ? <div className="flex border-secondary-two-110 bg-[#fcefe8] mt-2 p-2 border border-solid rounded-lg">
            <Discount height="24" width="24" />
            <Paragraph variant={VARIANT.MEDIUM} className="ml-2 font-nato-semibold">
              {t('amount_saved', {
            amount: formatCurrencyWithDecimal(totalDiscount + couponAmount, 2)
          })}
            </Paragraph>
          </div> : null}
      </div>
    </section>;
};
export default PriceDetail;