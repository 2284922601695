// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"xXrqWU45NbxPoRK_x6ehB"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { isCancel } from 'axios'
const { version } = require('./package.json')

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: `kisan-app-web_${version}_${process.env.NEXT_PUBLIC_ENVIRONMENT}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.25,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    'getReadModeConfig',
    'getReadModeExtract',
    'getReadModeRender',
    /Network Error/i,
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  beforeSend(event, hint) {
    const error = hint.originalException as any

    const message = (error && error.message) || ''

    if (message.match(/highlighter is not defined/) || isCancel(error)) {
      return null
    }
    return event
  },

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [Sentry.replayIntegration()],
})
