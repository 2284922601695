import axios from 'axios'

import { CSCOrderDetails } from '@/models/Order'

import logToServer from '../logger-client'

const initiateCSCPayment = async (transctionDetails: CSCOrderDetails) => {
  try {
    const encryptedKeys = await axios.post(
      `/api/csc/encryption`,
      transctionDetails,
    )
    console.log(encryptedKeys, 'encryptedKeys')
    logToServer('info', {
      text: 'CSC encryptedKeys called',
      cscId: transctionDetails.cscId,
      txnAmount: transctionDetails.txnAmount,
    })
    const { data } = encryptedKeys?.data
    console.log(data, 'encrypted data')
    logToServer('info', {
      text: 'CSC encrypted data',
      cscId: transctionDetails.cscId,
      txnAmount: transctionDetails.txnAmount,
    })
    if (data?.urlFraction) {
      const paymentUrl = `${process.env.NEXT_PUBLIC_CSC_INITIATE_PAYMENT}/${data?.urlFraction}`
      console.log(paymentUrl, 'paymentUrl')
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = paymentUrl

      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'message'
      input.value = `${process.env.NEXT_PUBLIC_CSC_MERCHANT_ID}|${data?.encText}`
      console.log(
        `${process.env.NEXT_PUBLIC_CSC_MERCHANT_ID}|${data?.encText}`,
        '`${process.env.NEXT_PUBLIC_CSC_MERCHANT_ID}|${data?.encText}`',
      )
      form.appendChild(input)
      document.body.appendChild(form)

      form.submit()
      logToServer('info', {
        text: 'CSC Initiate payment called',
        value: `${process.env.NEXT_PUBLIC_CSC_MERCHANT_ID}|${data?.encText}`,
        paymentUrl,
      })
    }
  } catch (e) {
    logToServer('info', {
      text: 'CSC Initiate payment failed',
      error: e,
    })
    console.error(e)
  }
}

export default initiateCSCPayment
