import { ProductVariantV3 } from '@dehaat/kisan-app-bl/models/ProductV3';
import useTranslation from 'next-translate/useTranslation';
import { MouseEventHandler, useContext } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { AVAILABLE_STOCK } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { ProductVariant } from '@/models/Product';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { isShriProgramProduct, productCatalogueId, productDiscount, productPrice } from '@/utils/cart';
import AddRemoveButton from '../AddRemoveButton';
import ButtonLabel, { VARIANT } from '../ButtonLabel';
import PlusIcon from '../icons/PlusIcon';
interface Props {
  btnContainerStyle?: string;
  index?: number;
  listType?: ProductListTypeEnum;
  minQty?: number;
  productVariant: ProductVariant | ProductVariantV3;
  maxQty?: number;
}
const AddToCartNew = ({
  btnContainerStyle,
  index,
  listType,
  minQty,
  productVariant
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const {
    cartItems,
    updateCart,
    cartType
  } = useContext(CartContext);
  const stopBubbling: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const StockInSS = typeof window !== 'undefined' && sessionStorage?.getItem(AVAILABLE_STOCK);
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;
  const quantity = cartItems[productVariant.id] || 0;
  const disableRemove = !(typeof minQty === 'undefined' || quantity > minQty);
  const disableEditQuantity = isShriProgramProduct(productVariant.id, false);
  const diasbleAdd = availableStock && availableStock[productVariant.id] !== 'undefined' && quantity >= availableStock[productVariant.id];
  const onAddOrRemoveFromCart = (isAdd: boolean) => {
    let name, brandName;
    if (cartType === CART_TYPE.DEHAAT) {
      name = (productVariant as ProductVariant).product?.name;
      brandName = (productVariant as ProductVariant).product?.brand?.name;
    } else {
      name = (productVariant as ProductVariantV3).name;
      brandName = (productVariant as ProductVariantV3).brand.name;
    }
    updateCart({
      variantId: productVariant.id,
      quantity: 1,
      index,
      type: listType,
      variantPrice: productPrice(productVariant.inventory_set),
      variantDiscount: productDiscount(productVariant.inventory_set),
      name,
      brandName,
      catalogueId: productCatalogueId(productVariant.inventory_set)
    }, isAdd);
  };
  return quantity === 0 ? <button className="min-w-[110px] bg-primary-100 rounded-lg py-2.5 flex justify-center items-center" onClick={e => {
    onAddOrRemoveFromCart(true);
    trackCustomEvent(CustomEventTypes.ADD_TO_CART);
    stopBubbling(e);
  }} data-sentry-component="AddToCartNew" data-sentry-source-file="AddToCartNew.tsx">
      <PlusIcon height={20} width={20} className="fill-transparent stroke-white" data-sentry-element="PlusIcon" data-sentry-source-file="AddToCartNew.tsx" />
      <ButtonLabel variant={VARIANT.TWO} className="font-nato-semibold text-white ml-2" label={t('add')} data-sentry-element="ButtonLabel" data-sentry-source-file="AddToCartNew.tsx" />
    </button> : <AddRemoveButton btnContainerStyle={disableEditQuantity ? '' : btnContainerStyle} disableRemove={disableRemove || disableEditQuantity} disableAdd={diasbleAdd || disableEditQuantity} onAdd={() => {
    trackCustomEvent(CustomEventTypes.ADD_ONE_ITEM_TO_CART);
    onAddOrRemoveFromCart(true);
  }} onRemove={() => {
    trackCustomEvent(CustomEventTypes.REMOVE_ONE_ITEM_FROM_CART);
    if (!disableRemove) {
      onAddOrRemoveFromCart(false);
    }
  }} quantity={quantity} data-sentry-element="AddRemoveButton" data-sentry-component="AddToCartNew" data-sentry-source-file="AddToCartNew.tsx" />;
};
export default AddToCartNew;