import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import DateSelector from '@/components/askQuestion/SowingDate';
import HeaderWithBackButton from '@/components/HeaderWithBackButton';
import getFormattedDate from '@/utils/helpers/getFormattedDate';
interface Props {
  onModalClose: VoidFunction;
  handleContinue: (date: string) => void;
}
const SelectDeliveryDateModal = ({
  onModalClose,
  handleContinue
}: Props) => {
  const {
    t
  } = useTranslation('cropGuide');
  const [month, setMonth] = useState<number>(NaN);
  const [week, setWeek] = useState<number>(NaN);
  const [year, setYear] = useState<number>(NaN);
  const preventClick = isNaN(month) || isNaN(week) || isNaN(year);
  return <>
      <HeaderWithBackButton heading={t('cart:select_delivery_date')} showHomeIcon={false} showProfileIcon={false} onBackClick={onModalClose} data-sentry-element="HeaderWithBackButton" data-sentry-source-file="SelectDeliveryDateModal.tsx" />
      <div className="fixed top-14 left-0 h-screen bg-white p-4 pb-28">
        <DateSelector allowedYears={[new Date().getFullYear(), new Date().getFullYear() + 1]} isPerennial={true} month={month} week={week} onSetMonth={month => setMonth(month)} onSetWeek={week => setWeek(week)} onSetYear={year => setYear(year)} year={year} disablePreviousDates data-sentry-element="DateSelector" data-sentry-source-file="SelectDeliveryDateModal.tsx" />

        <footer className="fixed bottom-0 left-0 right-0 p-1 bg-white z-10">
          <button onClick={() => handleContinue(getFormattedDate(month, year, week))} disabled={preventClick} className={`w-webkit-fill cursor-pointer py-4 m-5 rounded-lg ${preventClick ? 'bg-neutral-30' : 'bg-primary-100'} text-white text-center font-nato-semibold`}>
            {t('address:continue')}
          </button>
        </footer>
      </div>
      )
    </>;
};
export default SelectDeliveryDateModal;