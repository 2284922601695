function logToServer(level, params) {
  const { host, pathname } = window.location
  const message = {
    ...params,
    host,
    pathname,
  }
  fetch('/api/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ level, message }),
  })
}

export default logToServer
