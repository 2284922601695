export enum DELIVERY_TYPE {
  HOME_DELIVERY = 'home_delivery',
  SELF_PICKUP = 'pickup_from_store',
}

export enum DELIVERY_FULFILLMENT_TYPE {
  BOTH = 'both',
  HOME_DELIVERY = 'home_delivery',
  SELF_PICKUP = 'pickup_from_store',
}

export interface ProductOutOfStock {
  id: string
  brandName?: string
  productName?: string
  quantity?: string
  productImage?: string | null
}

export interface AvailbleStockError {
  item_id: string
  available_qty: number
}

export interface CartValidationError {
  message: string
  details?: {
    product_variant_id: number
    catalog_id?: number
  }
}
