import axios from 'axios'

import { APP_CODE_TYPE } from '@/models/Auth'
import URL from '@/utils/axios/url'

import getClientCode from '../helpers/getClientCode'

type Response = { otp_sent: 'success' | 'failed' }

const sendOtp = async (
  mobileNumber: string,
  appCode?: boolean | APP_CODE_TYPE
) => {
  const body = {
    mobile_number: mobileNumber,
    client_id: getClientCode(appCode),
  }
  try {
    const { otp_sent } = (
      await axios.post<Response>(URL.KEYCLOAK_PROXY_SENDOTP_API, body)
    ).data
    return otp_sent === 'success'
  } catch {
    return false
  }
}

export default sendOtp
