import useTranslation from 'next-translate/useTranslation';
import Paragraph, { VARIANT } from '@/components/Paragraph';
import getWeekOfMonth from '@/utils/helpers/getWeekOfMonth';
import MonthSelector from './MonthSelector';
import YearSelector from './YearSelector';
interface Props {
  isPerennial: boolean;
  month: number;
  onSetMonth: (month: number) => void;
  onSetWeek: (week: number) => void;
  onSetYear: (year: number) => void;
  week: number;
  year: number;
  disablePreviousDates?: boolean;
  allowedYears?: number[];
}
const SowingDate = ({
  isPerennial,
  month,
  onSetMonth,
  onSetWeek,
  onSetYear,
  week,
  year,
  disablePreviousDates = false,
  allowedYears = []
}: Props) => {
  const {
    t
  } = useTranslation('sowingDate');
  return <section data-sentry-component="SowingDate" data-sentry-source-file="SowingDate.tsx">
      {isPerennial && <YearSelector onSetYear={onSetYear} year={year} allowedYears={allowedYears} />}
      <MonthSelector month={month} onSetMonth={onSetMonth} onSetYear={onSetYear} year={year} disablePrevSelections={disablePreviousDates} data-sentry-element="MonthSelector" data-sentry-source-file="SowingDate.tsx" />
      <div>
        <Paragraph isHighlighted variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="SowingDate.tsx">
          {t('select_week')}
        </Paragraph>
        <div className="flex flex-wrap gap-4 mt-2">
          {[0, 1, 2, 3, 4].map(index => <button disabled={disablePreviousDates && new Date().getMonth() === month && year === new Date().getFullYear() && index <= getWeekOfMonth() - 1} className={`border font-nato-medium grow-0 px-3 py-2 rounded-lg shrink-0 text-sm ${week === index ? 'bg-primary-10 border-primary-100' : 'border-neutral-20'} ${disablePreviousDates && new Date().getMonth() === month && year === new Date().getFullYear() && index <= getWeekOfMonth() - 1 ? 'text-gray-300' : ''}`} key={`week_${index}`} onClick={() => onSetWeek(index)}>
              {t(`week_${index}`)}
            </button>)}
        </div>
      </div>
    </section>;
};
export default SowingDate;