/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from 'react';
interface Props {
  alt: string;
  fallBackIcon: JSX.Element;
  imageUrl?: string | null;
  className?: string;
  fallBackClass?: string;
}

/**
 * A react component for loading images lazily
 * @author Ankit Dua <ankit.dua@agrevolution.in>
 */

const LazyImage = ({
  alt,
  fallBackIcon,
  imageUrl,
  className = '',
  fallBackClass = ''
}: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    // If image is loaded from cache
    if (imageRef.current?.complete) {
      setIsImageLoaded(true);
    }
    return () => {
      setIsImageLoaded(false);
    };
  }, [imageUrl]);
  return <>
      {imageUrl && !isError ? <img ref={imageRef} src={imageUrl} alt={alt} loading="lazy" onLoad={() => setIsImageLoaded(true)} onError={() => setIsError(true)} className={`${className} ${isImageLoaded ? '' : 'invisible'}`.trim()} /> : null}
      {!isImageLoaded && isError || !imageUrl ? <div className={`absolute top-0 h-full w-full ${fallBackClass}`}>
          {fallBackIcon}
        </div> : null}
    </>;
};
export default LazyImage;