const URL = {
    KEYCLOAK_PROXY_SENDOTP_API: '/api/keycloak/sendOTP',
    KEYCLOAK_PROXY_LOGOUT_API: '/api/keycloak/logout',
    KEYCLOAK_PROXY_TOKEN_API: '/api/keycloak/token',
    GET_KEYCLOCK_TOKEN: 'realms/dehaat/protocol/openid-connect/token',
    GET_CSC_ENCRYPTED_STRING: 'csc/encrypt',
    GET_CSC_DECRYPTED_STRING: 'csc/decrypt',
    
    //** NEXT API'S*/
    CSC_PROCESS_PAYMENT: 'api/csc/process-payment',

    //** Payment API'S */
    UPDATE_PAYMENT_PG: '/payment/v1/webhook/update-payment',
}

export default URL
