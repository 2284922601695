import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import useSpeechRecognition from '@/hooks/useSpeechRecognition';
import styles from '@/styles/SearchBox.module.css';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { withSpecialCharFilter } from '@/utils/validation';
import AppBackButton from './AppBackButton';
import BackArrow from './icons/BackArrow';
import Cross from './icons/Cross';
import MagnifyingGlass from './icons/MagnifyingGlass';
import Mic from './icons/Mic';
import RecentSearches from './products/RecentSearches';
interface Props {
  placeholder: string;
  onSearch: (text: string) => void;
  lang: string;
  initialSearchText?: string;
  onFocus?: VoidFunction;
  showBackBtn?: boolean;
  onBackClick?: VoidFunction;
  setIsTyped?: Dispatch<SetStateAction<boolean>>;
  recentSearches?: string[];
}
interface ButtonWithMicProps {
  showButton: boolean;
  isListening: boolean;
  onStartListening: VoidFunction;
  onStopListening: VoidFunction;
  onFocus?: VoidFunction;
}
const ButtonWithMic = ({
  showButton,
  isListening,
  onStartListening,
  onStopListening,
  onFocus
}: ButtonWithMicProps) => {
  return showButton ? <button className={`${isListening ? 'bg-error-10' : ''} rounded-r-lg w-12 absolute top-px right-px z-10 flex justify-center items-center ${styles.micHeight}`} aria-label="Click for searching via speech" onClick={() => {
    trackCustomEvent(CustomEventTypes.SEARCH_BY_VOICE);
    if (onFocus) {
      onFocus();
    }
    isListening ? onStopListening() : onStartListening();
  }} data-sentry-component="ButtonWithMic" data-sentry-source-file="SearchBox.tsx">
      <Mic className={`${isListening ? 'stroke-error-100' : 'stroke-primary-100'} fill-transparent h-6 w-[25px]`} data-sentry-element="Mic" data-sentry-source-file="SearchBox.tsx" />
    </button> : null;
};

/**
 * An input box which can be used for searching. The component is equipped with
 * Speech recognition and works in all browsers except for Firefox and IE.
 *
 * @author Sumit Kumar Surana - <sumit.surana@agrevolution.in>
 */
const SearchBox = ({
  placeholder,
  onSearch,
  onFocus,
  onBackClick,
  lang,
  initialSearchText = '',
  showBackBtn = false,
  setIsTyped,
  recentSearches
}: Props) => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const {
    t
  } = useTranslation('main');
  const {
    onStartListening,
    isListening,
    stopListening,
    isSpeechRecognitionSupported
  } = useSpeechRecognition(setSearchText, lang);
  const crossHandler = () => {
    setSearchText('');
    trackCustomEvent(CustomEventTypes.SEARCH_RESET);
  };
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsTyped && setIsTyped(true);
    const value = e.currentTarget.value;
    if (value === 'cmdSentryManualCrash') {
      try {
        throw new Error('This is a manual crash for testing Sentry');
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      setSearchText(value);
    }
  }, [setIsTyped]);
  useEffect(() => {
    onSearch(searchText);
  }, [searchText, onSearch]);
  const backArrowClassname = 'absolute top-1/2 -translate-y-1/2 left-4 z-10';
  let backButton: JSX.Element;
  if (onBackClick) {
    backButton = <button className={backArrowClassname} aria-label="Go back" onClick={() => {
      setSearchText('');
      onBackClick();
    }}>
        <BackArrow className="fill-transparent stroke-neutral-80 w-6 h-6" />
      </button>;
  } else {
    backButton = <AppBackButton btnClassName={backArrowClassname} />;
  }
  return <section className="flex flex-col" data-sentry-component="SearchBox" data-sentry-source-file="SearchBox.tsx">
      <div className="relative">
        {showBackBtn ? backButton : <MagnifyingGlass className="fill-transparent stroke-neutral-80 w-6 h-6 absolute top-1/2 -translate-y-1/2 left-4 z-10" role="presentation" aria-hidden="true" />}
        <input type="text" onChange={withSpecialCharFilter(onChange)} className="shadow-[0px_0px_10px_0px_rgba(0,0,0,0.15)] outline-none w-full rounded-lg text-xs placeholder:text-neutral-40 py-3 pr-13 pl-12 border border-neutral-20" placeholder={placeholder} value={searchText} onFocus={onFocus} />

        <div className="border-dotted border-l-[1px] border-neutral-40  w-6  absolute top-2  right-6  z-10 h-6"></div>
        {searchText ? <button className={`rounded-r-lg absolute top-px right-px z-10 flex justify-center items-center ${styles.micHeight} w-12`} aria-label="Click to clear query" onClick={crossHandler}>
            <Cross className="fill-transparent stroke-neutral-80 w-6 h-6" />
          </button> : <>
            <ButtonWithMic showButton={isSpeechRecognitionSupported} onFocus={onFocus} onStartListening={onStartListening} onStopListening={stopListening} isListening={isListening} />
          </>}
      </div>

      {recentSearches && recentSearches.length > 0 && <div className="p-2">
          <h2 className="font-medium text-2xl">{t('recent_search')}</h2>

          {recentSearches.map((search, index) => <div key={index}>
              <RecentSearches recentSearch={search} onClick={searchKey => setSearchText(searchKey)} />
            </div>)}
        </div>}
    </section>;
};
export default SearchBox;