import { ProductVariantV3 } from '@dehaat/kisan-app-bl/models/ProductV3';
import useTranslation from 'next-translate/useTranslation';
import { MouseEventHandler, useContext } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { AVAILABLE_STOCK } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { ProductVariant } from '@/models/Product';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { isShriProgramProduct, productCatalogueId, productDiscount, productPrice } from '@/utils/cart';
import { isArray } from '@/utils/helper';
import ButtonLabel, { VARIANT } from '../ButtonLabel';
import HyperLocalAddRemoveButton from './HyperLocalAddRemoveButton';
interface Props {
  btnContainerStyle?: string;
  index?: number;
  listType?: ProductListTypeEnum;
  minQty?: number;
  productVariant: ProductVariant | ProductVariantV3;
  maxQty?: number;
  isDrawerVisible?: boolean;
  isOutOfStock?: boolean;
}
const HyperLocalAddToCart = ({
  btnContainerStyle,
  index,
  listType,
  minQty,
  productVariant,
  isDrawerVisible = false,
  isOutOfStock
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const {
    cartItems,
    updateCart,
    cartType
  } = useContext(CartContext);
  const stopBubbling: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const StockInSS = typeof window !== 'undefined' && sessionStorage?.getItem(AVAILABLE_STOCK);
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;
  const quantity = cartItems[productVariant?.id] || 0;
  const disableRemove = !(typeof minQty === 'undefined' || quantity > minQty);
  const disableEditQuantity = isShriProgramProduct(productVariant?.id, false);
  const diasbleAdd = availableStock && availableStock[productVariant?.id] !== 'undefined' && quantity >= availableStock[productVariant?.id];
  const onAddOrRemoveFromCart = (isAdd: boolean) => {
    let name, brandName;
    if (cartType === CART_TYPE.DEHAAT) {
      name = (productVariant as ProductVariant).product?.name;
      brandName = (productVariant as ProductVariant).product?.brand?.name;
    } else {
      name = (productVariant as ProductVariantV3).name;
      brandName = (productVariant as ProductVariantV3).brand.name;
    }
    const inventorySet = productVariant.inventory_set;
    updateCart({
      variantId: productVariant.id,
      quantity: 1,
      index,
      type: listType,
      variantPrice: productPrice(inventorySet),
      variantDiscount: productDiscount(inventorySet),
      name,
      brandName,
      catalogueId: productCatalogueId(inventorySet)
    }, isAdd);
  };
  return quantity === 0 ? <button className={` rounded-lg py-2.5 justify-center items-center mt-2 ${isDrawerVisible ? 'w-32' : 'w-full flex-1'}
    ${isOutOfStock ? 'bg-[#C5CED6]' : 'bg-primary-100'}`} onClick={e => {
    if (!isOutOfStock) {
      onAddOrRemoveFromCart(true);
      trackCustomEvent(CustomEventTypes.ADD_TO_CART);
      stopBubbling(e);
    }
  }} data-sentry-component="HyperLocalAddToCart" data-sentry-source-file="HyperLocalAddToCart.tsx">
      <ButtonLabel variant={VARIANT.TWO} className="font-nato-semibold text-white ml-2" label={t('add')} data-sentry-element="ButtonLabel" data-sentry-source-file="HyperLocalAddToCart.tsx" />
    </button> : <HyperLocalAddRemoveButton btnContainerStyle={disableEditQuantity ? '' : btnContainerStyle} disableRemove={disableRemove || disableEditQuantity} disableAdd={diasbleAdd || disableEditQuantity} onAdd={() => {
    trackCustomEvent(CustomEventTypes.ADD_ONE_ITEM_TO_CART);
    onAddOrRemoveFromCart(true);
  }} onRemove={() => {
    trackCustomEvent(CustomEventTypes.REMOVE_ONE_ITEM_FROM_CART);
    if (!disableRemove) {
      onAddOrRemoveFromCart(false);
    }
  }} quantity={quantity} isDrawerVisible={isDrawerVisible} data-sentry-element="HyperLocalAddRemoveButton" data-sentry-component="HyperLocalAddToCart" data-sentry-source-file="HyperLocalAddToCart.tsx" />;
};
export default HyperLocalAddToCart;