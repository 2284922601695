import createOrderV4 from '@dehaat/kisan-app-bl/api/createOrderV4';
import payByPaymentGateway, { PaymentGatewayBody } from '@dehaat/kisan-app-bl/api/payByPaymentGateway';
import { Address, FarmerDetails } from '@dehaat/kisan-app-bl/models/AddressV2';
import { OrderDetailsV4, PaymentOptions } from '@dehaat/kisan-app-bl/models/Order';
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Okay from '@/components/modalActions/Okay';
import ChooseAmountDrawer from '@/components/payments/ChooseAmountDrawer';
import PaymentDrawer from '@/components/payments/PaymentDrawer';
import RoundedBottomDrawer from '@/components/RoundedBottomDrawer';
import Spinner from '@/components/Spinner';
import SubHeading, { VARIANT } from '@/components/SubHeading';
import { ACCESS_TOKEN_KEY, AVAILABLE_STOCK, CSC_MERCHANT_DATE_TYPE, IS_PREBOOK_FLOW, MY_CART, ORDER_ID_KEY, OUT_OF_STOCK_PRODUCTS, SHRI_PROGRAM_USER_DETAILS } from '@/constants/common';
import { CSC } from '@/constants/csc';
import { KYC_AADHAR_KEY, KYC_PAN_CARD_KEY } from '@/constants/insurance';
import { CSC_LOGIN } from '@/constants/login';
import { PAYMENT_OPTIONS } from '@/constants/payments';
import { CartContext } from '@/context/CartProvider';
import { ErrorModalContext } from '@/context/ErrorModalProvider';
import { InsuranceContext } from '@/context/InsuranceProvider';
import { InsuranceDataItem } from '@/models/api/productInsurance';
import { CartValidationError } from '@/models/Cart';
import { AvailableCoupon } from '@/models/Coupon';
import { OrderError } from '@/models/Order';
import { CartStateInStorage, ProductVariant } from '@/models/Product';
import { ShriUserInLS } from '@/models/shriProgram';
import { capturePurchaseEvent } from '@/utils/analytics';
import { conversionLabels } from '@/utils/analytics/conversionLabels';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent, trackGAConversionEvent } from '@/utils/analytics/googleAnalytics';
import fetchMasterDocumentTypes from '@/utils/api/fetchMasterDocumentTypes';
import initiateCSCPayment from '@/utils/api/initiateCSCPayment';
import URL from '@/utils/axios/url';
import { createOrderQuery, hasInsurance, productDiscount, productPrice, updateCartInLocalStorage } from '@/utils/cart';
import { createUUID, generateDateString, getCookieValue, getLocalStorageKey, isProductionBuild, removeLocalStorageKey, setLocalStorageKey } from '@/utils/helper';
import { formatCurrencyWithDecimal } from '@/utils/helpers/formatCurrency';
import parseJWT from '@/utils/parseToken';
import KycPrompt from '../insurance/KycPrompt';
import SelectDeliveryDateModal from './SelectDeliveryDateModal';
const Otp = dynamic(() => import('../insurance/Otp'), {
  ssr: false,
  loading: () => <Spinner />
});
const Kyc = dynamic(() => import('../insurance/Kyc'), {
  ssr: false,
  loading: () => <Spinner />
});
interface Props {
  appliedCoupon: AvailableCoupon | null;
  bankKycStatus: boolean | null;
  cartList: ProductVariant[];
  idKycStatus: boolean | null;
  increaseZIndex: VoidFunction;
  insuranceData: InsuranceDataItem[];
  kycTodoList: string[];
  resetZIndex: VoidFunction;
  selectedAddress: Address | null;
  shippingCharges: number;
  totalDiscount: number;
  totalInsuranceCost: number;
  totalVariantCost: number;
  updateKycData: VoidFunction;
  validateCart: (message: CartValidationError) => void;
  vendor: Vendor;
  isPayNow: boolean;
  prePayAmount: number;
  paymentOptions?: PaymentOptions;
  cumulative_commission: number;
}
const PlaceOrder = ({
  appliedCoupon,
  bankKycStatus,
  cartList,
  idKycStatus,
  increaseZIndex,
  insuranceData,
  kycTodoList,
  resetZIndex,
  selectedAddress,
  shippingCharges,
  totalDiscount,
  totalInsuranceCost,
  totalVariantCost,
  updateKycData,
  validateCart,
  vendor,
  isPayNow,
  prePayAmount,
  paymentOptions,
  cumulative_commission
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  const {
    cartType,
    cartId,
    cartItems,
    pickupFarmerDetails,
    resetCart,
    isPickupOrder
  } = useContext(CartContext);
  const paymentDrawerAmountRef = useRef<number>();
  const disablePlaceOrder = Object.keys(cartItems).length === 0;
  const {
    insuranceItems,
    resetInsuranceItems
  } = useContext(InsuranceContext);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showSelectAmountDrawer, setShowSelectAmountDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState<PAYMENT_OPTIONS>(PAYMENT_OPTIONS.COD);
  const [bookingDate, setBookingDate] = useState<string>();
  const isPrebookOrder = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null)?.[cartType]?.isPrebook;
  const {
    replace,
    push,
    reload
  } = useRouter();
  const [processing, setProcessing] = useState(false);
  const [promptKYC, setPromptKYC] = useState(false);
  const [showDeliveryDateModal, setShowDeliveryDateModal] = useState(false);
  const {
    errorModalState,
    setErrorModalState
  } = useContext(ErrorModalContext);
  const couponPrice = appliedCoupon ? Number(appliedCoupon.discount) : 0;
  const finalCost = totalVariantCost + totalInsuranceCost - totalDiscount + shippingCharges - couponPrice;
  const isInsuranceAdded = hasInsurance(insuranceItems, cartItems);
  const [showKycModule, setShowKycModule] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [[aadharTypeId, panTypeId], setIdType] = useState<(number | null)[]>([null, null]);
  const getCSCUser = () => {
    const accessToken = getCookieValue(ACCESS_TOKEN_KEY) || null;
    if (accessToken) {
      const {
        external_partner_id,
        external_partner_type
      } = parseJWT(accessToken);
      if (external_partner_type === CSC_LOGIN.CSC && external_partner_id) return external_partner_id;
    }
    // return 500100100013
  };
  const farmerDetails: FarmerDetails | undefined = useMemo(() => {
    if (pickupFarmerDetails) {
      return {
        auth_id: pickupFarmerDetails.auth_id || '',
        full_name: pickupFarmerDetails.full_name || '',
        id: pickupFarmerDetails.id,
        phone_number: pickupFarmerDetails.phone_number
      };
    } else {
      return selectedAddress?.farmer_details;
    }
  }, [pickupFarmerDetails, selectedAddress]);
  const triggerShowKycModule = async () => {
    setShowKycModule(true);
    const documentTypes = await fetchMasterDocumentTypes('identity_proof_type', [KYC_AADHAR_KEY, KYC_PAN_CARD_KEY], [1, 5]);
    setIdType(documentTypes);
  };
  const onOrderPlaced = (id: number) => {
    if (typeof window !== 'undefined' && window.scq) {
      const utmParams = sessionStorage.getItem('utm_params');
      if (utmParams) {
        try {
          if (JSON.parse(utmParams).utm_source === 'sharechat') {
            window.scq('Purchase', 'pre_defined');
          }
        } catch (error) {
          console.error('Failed to parse utm_params from sessionStorage:', error);
        }
      }
    }
    capturePurchaseEvent({
      orderId: id,
      value: finalCost,
      coupon: appliedCoupon?.couponCode,
      shipping: shippingCharges,
      products: cartList.map(variant => ({
        productId: variant.product?.id,
        brandId: variant.product?.brand?.id,
        itemName: variant.product?.name,
        variantId: variant.id,
        discount: productDiscount(variant.inventory_set),
        price: productPrice(variant.inventory_set),
        quantity: cartItems[variant.id] || 0,
        vendorName: vendor.owner_name
      }))
    });
    trackCustomEvent(CustomEventTypes.ORDER_CREATED);
    trackGAConversionEvent(CustomEventTypes.ORDER_CREATED, {
      transaction_id: id
    }, conversionLabels.ORDER_CREATED);
  };
  const placeCSCOrderHandler = async (id: number, cscUserId: string, session_id: string) => {
    const cscIntiatePaymentDetails = {
      merchantId: process.env.NEXT_PUBLIC_CSC_MERCHANT_ID,
      merchantTxn: id.toString(),
      productId: process.env.NEXT_PUBLIC_CSC_WALLET_PRODUCT_ID_B2C,
      merchantTxnDateTime: generateDateString(CSC_MERCHANT_DATE_TYPE),
      txnAmount: finalCost,
      txnMode: CSC.TRANSACTION_MODE,
      txnType: CSC.TRANSACTION_MODE,
      merchantReceiptNo: createUUID(),
      cscShareAmount: cumulative_commission,
      payToEmail: '',
      returnUrl: `${process.env.NEXT_PUBLIC_DIGIACRE_CSC_SUB_DOMAIN}/${URL.CSC_PROCESS_PAYMENT}`,
      cancelUrl: `${process.env.NEXT_PUBLIC_DIGIACRE_CSC_SUB_DOMAIN}/${URL.CSC_PROCESS_PAYMENT}`,
      cscId: cscUserId,
      currency: CSC.CURRENCY,
      amountParameter: 'NA',
      discount: 0,
      param1: session_id,
      param2: id,
      param3: 'NA',
      param4: 'NA',
      encFor: 'Payment'
    };
    await initiateCSCPayment(cscIntiatePaymentDetails);
  };

  // NOTE (Tech debt): this should be a useCallback since
  // it's used down the line after insurance OTP is successful
  // Since it changes every time, OTP handleSubmit is called infinite times
  // resulting in multiple OTPs being created
  // Adding the useCallback dependencies correctly would require careful analysis of this function

  // Hence, creating the tech debt
  const placeOrderHandler = async (hashCode?: string, skipInsurance = false, deliveryDate?: string, paymentOption?: PAYMENT_OPTIONS, paymentGatewayAmount?: number) => {
    setProcessing(true);
    setShowPaymentDrawer(false);
    setShowSelectAmountDrawer(false);
    setShowDeliveryDateModal(false);
    setShowOtpScreen(false);
    const selection = paymentOption ?? selectedOption;
    // Credit case needs to be handled here once credit feature is available on Mweb

    const paymentModeId = getCSCUser() ? paymentOptions?.csc_wallet.payment_mode_id : selection === PAYMENT_OPTIONS.PAY_NOW ? paymentOptions?.payment_gateway.payment_mode_id : paymentOptions?.cash.payment_mode_id;
    const query = createOrderQuery(isPickupOrder, getLocalStorageKey<ShriUserInLS, null>(SHRI_PROGRAM_USER_DETAILS)?.mobileNumber || farmerDetails?.phone_number || '', cartId, cartList, totalVariantCost, skipInsurance ? 0 : totalInsuranceCost, totalDiscount, shippingCharges, true, cartItems, appliedCoupon, selectedAddress, skipInsurance ? {} : insuranceItems, skipInsurance ? [] : insuranceData, !skipInsurance && idKycStatus || false, !skipInsurance && bankKycStatus || false, hashCode, vendor, deliveryDate || bookingDate, false, cumulative_commission);
    query.payment = {
      transactions: [{
        payment_mode: paymentModeId,
        amount: getCSCUser() ? finalCost - cumulative_commission : finalCost
      }]
    };

    // FSS-2545: KYC is not required to buy insurance for now as discussed with Satish.
    // Add `&& idKycStatus` to the following IF condition when KYC is required again.
    if (isInsuranceAdded && !skipInsurance && !hashCode) {
      setShowOtpScreen(true);
      setProcessing(false);
      return;
    }
    try {
      const orderResponse = await createOrderV4(query);
      const {
        error
      } = (orderResponse as OrderError);
      if (error) {
        if (error.code === 'CART_01') {
          validateCart({
            message: error.message
          });
        }
      } else {
        const orderDetails = (orderResponse as OrderDetailsV4);
        const request_id = orderDetails.request_id;
        const {
          id,
          buyer_details,
          data: {
            address,
            vendor
          }
        } = orderDetails.orders[0];
        onOrderPlaced(id);
        const cscUserId = getCSCUser();
        if (selection === PAYMENT_OPTIONS.PAY_NOW || cscUserId) {
          // TODO: Check if this is even required as we already reset cart on page load
          updateCartInLocalStorage(cartType, undefined, undefined, createUUID(), undefined);
          let totalAmount = finalCost;
          switch (true) {
            case !!cscUserId:
              totalAmount = finalCost;
              break;
            case !!paymentGatewayAmount:
              totalAmount = (paymentGatewayAmount as number);
              break;
            case isPrebookOrder:
              totalAmount = prePayAmount;
              break;
            case skipInsurance:
              totalAmount = finalCost - totalInsuranceCost;
              break;
            default:
              totalAmount = finalCost;
          }
          const contactNumber = address?.contact_number ? address.contact_number.substring(address.contact_number.length - 10) : '';
          const body: PaymentGatewayBody = {
            total_amount: totalAmount,
            entity_id: isProductionBuild ? 5 : 4,
            channel: 'Web',
            first_name: address?.contact_name || buyer_details.name || '',
            mobile: contactNumber || buyer_details.number || '',
            billing_address: address?.full_display_address || vendor.company_address || 'NA',
            entity_metadata: {
              vendor: Number(vendor.id),
              order_creation_request_id: request_id
            },
            gateway: cscUserId ? CSC.CSC_GATWAY : undefined
          };
          const paymentGatewayResponse = await payByPaymentGateway(body);
          if (paymentGatewayResponse) {
            sessionStorage.setItem(ORDER_ID_KEY, id.toString());
            if (paymentGatewayResponse?.session_id && cscUserId) {
              await placeCSCOrderHandler(request_id, cscUserId, paymentGatewayResponse?.session_id);
            } else {
              push(paymentGatewayResponse.url).then(() => setProcessing(false));
            }
          }
        } else {
          if ('ReactNativeWebView' in window) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;
            (window as any).ReactNativeWebView.postMessage(JSON.stringify({
              code: 'productOrdered',
              message: id.toString()
            }));
          }
          await replace(`/orders`);
          resetCart();
          setLocalStorageKey(IS_PREBOOK_FLOW, false);
          removeLocalStorageKey(SHRI_PROGRAM_USER_DETAILS);
          if ('sessionStorage' in window) {
            sessionStorage.removeItem(AVAILABLE_STOCK);
            sessionStorage.removeItem(OUT_OF_STOCK_PRODUCTS);
          }
          setProcessing(false);
        }
      }
    } catch {
      setErrorModalState({
        ...errorModalState,
        show: true,
        actionNode: <Okay handleClick={() => reload()} />,
        allowClose: false
      });
      setProcessing(false);
    }
  };
  const handlePaymentSelection = (selectedOption: PAYMENT_OPTIONS) => {
    setSelectedOption(selectedOption);
    placeOrderHandler(undefined, undefined, undefined, selectedOption);
  };
  const onPlaceOrderClick = () => {
    if (isPrebookOrder) {
      setShowDeliveryDateModal(true);
    } else if (!isNaN(prePayAmount) && prePayAmount > 0 && !getCSCUser()) {
      setShowSelectAmountDrawer(true);
    } else if (isPayNow && !getCSCUser()) {
      setShowPaymentDrawer(true);
    } else {
      placeOrderHandler();
    }
  };
  useEffect(() => {
    if (showKycModule || showOtpScreen || showDeliveryDateModal) {
      increaseZIndex();
    } else {
      resetZIndex();
    }
  }, [increaseZIndex, resetZIndex, showKycModule, showOtpScreen, showDeliveryDateModal]);
  return <>
      {showDeliveryDateModal && <SelectDeliveryDateModal onModalClose={() => setShowDeliveryDateModal(false)} handleContinue={date => {
      setBookingDate(date);
      if (!isNaN(prePayAmount) && prePayAmount > 0 && !getCSCUser()) {
        setShowSelectAmountDrawer(true);
      } else {
        placeOrderHandler(undefined, undefined, date);
      }
    }} />}
      {processing && <Spinner />}
      <div className="flex justify-between items-center">
        <SubHeading variant={VARIANT.BIG} className="font-semibold" data-sentry-element="SubHeading" data-sentry-source-file="PlaceOrder.tsx">
          {formatCurrencyWithDecimal(finalCost, 2)}
        </SubHeading>
        <button className={`${disablePlaceOrder ? 'bg-neutral-30' : 'bg-primary-100'} h-12 px-8 py-3 rounded-lg text-center text-white`} onClick={onPlaceOrderClick} data-testid="place-order-btn" disabled={disablePlaceOrder}>
          {isPrebookOrder ? t('place_prebook_order') : t('place_order')}
        </button>
      </div>
      {/* FSS-2545: KYC is not required to buy insurance for now as discussed with Satish.
       Remove `false` from the following IF condition when KYC is required again. */}
      {false && isInsuranceAdded && !idKycStatus ? <RoundedBottomDrawer show={promptKYC} onClose={() => setPromptKYC(false)}>
          <KycPrompt onCancel={() => {
        setPromptKYC(false);
        placeOrderHandler(undefined, true);
        resetInsuranceItems();
      }} onProceed={() => {
        setPromptKYC(false);
        triggerShowKycModule();
      }} />
        </RoundedBottomDrawer> : null}
      {isInsuranceAdded && showKycModule ? <section className="top-0 left-0 z-50 fixed bg-neutral-10 w-screen h-screen overflow-hidden">
          <Kyc onBack={() => setShowKycModule(false)} onOkay={status => {
        updateKycData();
        if (kycTodoList.includes(status)) {
          setShowOtpScreen(true);
        }
        setShowKycModule(false);
      }} panTypeId={panTypeId} aadharTypeId={aadharTypeId} />
        </section> : null}
      {isInsuranceAdded && showOtpScreen && idKycStatus != null && bankKycStatus != null ? <section className="top-0 left-0 z-50 fixed bg-neutral-10 w-screen h-screen overflow-hidden">
          <Otp bankKycStatus={bankKycStatus}
      // farmerDetails is guaranteed to be set when OTP screen is shown
      // This is done by assertions made while adding insurance
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      farmer={farmerDetails!} idKycStatus={idKycStatus} insuranceData={insuranceData} onBack={() => setShowOtpScreen(false)} onSkipOtp={() => placeOrderHandler(undefined, true)} onSuccess={hascode => placeOrderHandler(hascode, undefined, undefined, undefined, paymentDrawerAmountRef.current)} totalInsuranceCost={totalInsuranceCost} vendor={vendor} />
        </section> : null}

      <PaymentDrawer show={showPaymentDrawer} onClose={() => setShowPaymentDrawer(false)} onConfirmAndProceed={selectedOption => handlePaymentSelection(selectedOption)} data-sentry-element="PaymentDrawer" data-sentry-source-file="PlaceOrder.tsx" />
      <ChooseAmountDrawer show={showSelectAmountDrawer} onClose={() => setShowSelectAmountDrawer(false)} minimumAmount={prePayAmount} maximumAmount={finalCost} handleProceedClick={amount => {
      paymentDrawerAmountRef.current = amount;
      setSelectedOption(PAYMENT_OPTIONS.PAY_NOW);
      placeOrderHandler(undefined, undefined, bookingDate, PAYMENT_OPTIONS.PAY_NOW, amount);
    }} data-sentry-element="ChooseAmountDrawer" data-sentry-source-file="PlaceOrder.tsx" />
    </>;
};
export default PlaceOrder;