
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'intl-pluralrules';
import 'allsettled-polyfill';
import '@/styles/global.css';
import { configureKhetiAxiosInstance } from '@dehaat/kisan-app-bl/axios/configure';
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common';
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { wrapper } from 'src/store';
import GoogleAnalytics from '@/components/GoogleAnalytics';
import Spinner from '@/components/Spinner';
import { BOOKING_DETAIL, BOOKING_MANDATORY_DATA, BOOKING_OPTIONAL_DATA, COOKIE_MAX_AGE_1YEAR, CURRENT_LOCATION_KEY, CURRENT_VENDOR_KEY, LAT_LNG_COOKIE_NAME, SERVICE_CART, SERVICE_ICON_URL, SERVICE_ID, SERVICE_NAME, SERVICE_VENDOR_ID, SERVICE_VENDOR_NAME, VENDOR_ID_COOKIE_NAME, } from '@/constants/common';
import AuthProvider from '@/context/AuthProvider';
import CartProvider from '@/context/CartProvider';
import ErrorModalProvider from '@/context/ErrorModalProvider';
import InsuranceProvider from '@/context/InsuranceProvider';
import UserProvider from '@/context/UserProvider';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import CurrentLocation from '@/models/Location';
import { fetchHyperlocalData, selectLatLng, selectLoadingHyperlocalData, setCurrentLocation, } from '@/store/features/locationSlice';
import { setBookingDetail, setMandatoryFieldData, setOptionalFieldData, setServiceCart, setServiceIconUrl, setServiceId, setServiceName, setServiceVendorId, setServiceVendorName, } from '@/store/features/serviceSlice';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { initMixpanel } from '@/utils/analytics/mixpanel';
import { khetiConfig } from '@/utils/axios/khetiConfig';
import { getGeoLocationValues } from '@/utils/google';
import { getCookieValue, getLocalStorageKey, isDigiAcreUser, isProductionBuild, setLocalStorageKey, } from '@/utils/helper';
import { multiLocalStorageSync } from '@/utils/helpers/handleServiceCartSyncing';
import { askNotificationPermission } from '@/utils/helpers/promptNotifications';
configureKhetiAxiosInstance(khetiConfig);
function MyApp({ Component, pageProps }: AppProps<{
    title?: string;
}>) {
    const { asPath, replace, pathname, locale, query: { v }, } = useRouter();
    const dispatch = useAppDispatch();
    const latLng = useAppSelector(selectLatLng);
    // const loadingHyperlocalData = useAppSelector(selectLoadingHyperlocalData)
    // Initialize Mixpanel for tracking analytics when component mounts
    useEffect(() => {
        initMixpanel();
    }, []);
    // Trigger notification permission prompt after 40 seconds if not already granted
    useEffect(() => {
        const showAfterMilliseconds = 40000; // Configurable value for showing notification prompt
        if ('Notification' in window && Notification.permission === 'default') {
            setTimeout(() => {
                askNotificationPermission();
                trackCustomEvent(CustomEventTypes.NOTIFICATION_HARD_ASK_SHOWN, showAfterMilliseconds.toString());
            }, showAfterMilliseconds);
        }
    }, []);
    useEffect(() => {
        const getGeoLocation = async (latitude: number, longitude: number) => {
            const locationDetails = await getGeoLocationValues(latitude, longitude);
            dispatch(setCurrentLocation(locationDetails));
            await dispatch(fetchHyperlocalData({
                latLng: locationDetails.latLng,
                language: locale || DEFAULT_LANG,
                vendorId: v ? (v as string) : getCookieValue(VENDOR_ID_COOKIE_NAME),
                isCSCUser: isDigiAcreUser(),
            }));
            setLocalStorageKey(CURRENT_LOCATION_KEY, locationDetails);
            document.cookie = `${LAT_LNG_COOKIE_NAME}=${locationDetails.latLng}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
            replace(asPath);
        };
        const handleLocationSyncing = async () => {
            const latLngCookie = getCookieValue(LAT_LNG_COOKIE_NAME);
            const currentLocation = getLocalStorageKey<CurrentLocation, null>(CURRENT_LOCATION_KEY);
            const vendorInCookie = getCookieValue(VENDOR_ID_COOKIE_NAME);
            const currentVendor = getLocalStorageKey<Vendor | null, null>(CURRENT_VENDOR_KEY);
            // If latLng in cookie is different from currentLatLng
            if (latLngCookie && latLngCookie !== currentLocation?.latLng) {
                const latLngArr = latLngCookie.split(',');
                await getGeoLocation(+latLngArr[0], +latLngArr[1]);
            }
            // to sync redux locationSlice with LS
            else if (currentLocation && !latLng) {
                const coordinates = currentLocation.latLng.split(',');
                await getGeoLocation(+coordinates[0], +coordinates[1]);
            }
            // If vendorId in cookie or v is different from currentVendorId
            if (latLngCookie &&
                (vendorInCookie != currentVendor?.id || (v && v != currentVendor?.id))) {
                await dispatch(fetchHyperlocalData({
                    latLng: latLngCookie,
                    language: locale || DEFAULT_LANG,
                    vendorId: v ? (v as string) : vendorInCookie,
                    isCSCUser: isDigiAcreUser(),
                }));
                // To handle infinite re-loading in case of 500 and 404
                // proper fix for all pages is due.
                if (!pathname.includes('/500') && !pathname.includes('/404')) {
                    replace(asPath);
                }
            }
        };
        handleLocationSyncing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locale, replace, v]);
    // Syncing redux data with LS for service cart
    useEffect(() => {
        multiLocalStorageSync([
            { key: SERVICE_ID, defaultValue: undefined },
            { key: SERVICE_NAME, defaultValue: undefined },
            { key: SERVICE_ICON_URL, defaultValue: undefined },
            { key: SERVICE_CART, defaultValue: null },
            { key: BOOKING_MANDATORY_DATA, defaultValue: null },
            { key: BOOKING_OPTIONAL_DATA, defaultValue: null },
            { key: BOOKING_DETAIL, defaultValue: null },
            { key: SERVICE_VENDOR_ID, defaultValue: undefined },
            { key: SERVICE_VENDOR_NAME, defaultValue: undefined },
        ], (key, value) => {
            switch (key) {
                case SERVICE_ID:
                    value && dispatch(setServiceId(value));
                    break;
                case SERVICE_NAME:
                    value && dispatch(setServiceName(value));
                    break;
                case SERVICE_ICON_URL:
                    value && dispatch(setServiceIconUrl(value));
                    break;
                case SERVICE_CART:
                    value && dispatch(setServiceCart(value));
                    break;
                case BOOKING_MANDATORY_DATA:
                    value && dispatch(setMandatoryFieldData(value));
                    break;
                case BOOKING_OPTIONAL_DATA:
                    value && dispatch(setOptionalFieldData(value));
                    break;
                case BOOKING_DETAIL:
                    value && dispatch(setBookingDetail(value));
                    break;
                case SERVICE_VENDOR_ID:
                    value && dispatch(setServiceVendorId(value));
                    break;
                case SERVICE_VENDOR_NAME:
                    value && dispatch(setServiceVendorName(value));
                    break;
                default:
                    break;
            }
        });
    }, [dispatch]);
    // if (loadingHyperlocalData) return <Spinner />
    return (<Sentry.ErrorBoundary fallback={<h2>Oops, Something went wrong!</h2>}>
      <Head>
        {pageProps.title ? <title>{pageProps.title}</title> : null}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
      </Head>
      <AuthProvider>
        <UserProvider>
          <ErrorModalProvider>
            <CartProvider>
              <InsuranceProvider>
                <Component {...pageProps}/>
                <GoogleAnalytics />
                <Script id="facebook-pixel">
                  {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '1551884201879250');
                  fbq('track', 'PageView');
                  `}
                </Script>
                <Script id="google-tag-manager">
                  {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_KEY}');
      `}
                </Script>
                {isProductionBuild && (<Script id="sharechat-base-pixel">
                    {`(function (w, d, s, l, i) {
              w['scSdkId'] = i;
              w[l] = w[l] || []
              w.scq = function (eventName, eventType, p) {
              var props = p || {}
                w[l].push({ eventName: eventName, eventType: eventType, meta: props, eventFireTs: Date.now() })
              };
              w.scq("PAGE_VIEW", "AUTO", {
                pageUrl: w.location.href
              });
              var scr = d.createElement(s);
              scr.type = 'text/javascript';
              scr.async = true;
              scr.src = 'https://sc-events-sdk.sharechat.com/web-sdk.js';
              var x = d.getElementsByTagName(s)[0];
              x.parentNode.insertBefore(scr, x);
            })(window, document, "script", "scLayer", "BWYVh_xkeq");`}
                  </Script>)}
                <noscript>
                  <Image height="1" width="1" src="https://www.facebook.com/tr?id=1551884201879250&ev=PageView&noscript=1" alt="" style={{ display: 'none' }}/>
                </noscript>
                <noscript dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_KEY}" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}/>
              </InsuranceProvider>
            </CartProvider>
          </ErrorModalProvider>
        </UserProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>);
}
const __Next_Translate__Page__1948f9f2f5e__ = wrapper.withRedux(MyApp);

    export default __appWithI18n(__Next_Translate__Page__1948f9f2f5e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  