import { CancelReasons } from '@/models/Order'
export const cancelReasons: CancelReasons[] = [
  {
    title: 'cancel_order_reason_1',
  },
  {
    title: 'cancel_order_reason_2',
  },
  {
    title: 'cancel_order_reason_3',
  },
  {
    title: 'cancel_order_reason_4',
  },
]

export const cancelReasons2 = [
  {
    title: 'cancel_order_reason_1',
    code: '003',
  },
  {
    title: 'cancel_order_reason_2',
    code: '006',
  },
  {
    title: 'cancel_order_reason_3',
    code: '010',
  },
  {
    title: 'cancel_order_reason_4',
    code: '001',
  },
]

export const returnReasons = [
  {
    title: 'return_reason_1',
    code: '001',
  },
  {
    title: 'return_reason_2',
    code: '002',
  },
  {
    title: 'return_reason_3',
    code: '003',
  },
  {
    title: 'return_reason_4',
    code: '004',
  },
  {
    title: 'return_reason_5',
    code: '005',
  },
]
export const issueSubCategory = {
  ORDER: [
    {
      title: 'issue_reason_1',
      code: 'ORD03',
    },
  ],
  ITEM: [
    {
      title: 'issue_reason_2',
      code: 'ITM01',
    },
    {
      title: 'issue_reason_3',
      code: 'ITM02',
    },
    {
      title: 'issue_reason_4',
      code: 'ITM03',
    },
    {
      title: 'issue_reason_5',
      code: 'ITM04',
    },
    {
      title: 'issue_reason_6',
      code: 'ITM05',
    },
  ],
}

export const MAXIMUM_RETURN_IMAGES = 4

export const enum ORDER_STATUS {
  DeHaatCancelled = 'DEHAAT_CANCELLED',
  Delivered = 'DELIVERED',
  DeliveryFailed = 'FAILED_DELIVERY',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  UserCancelled = 'USER_CANCELLED',
  VendorCancelled = 'VENDOR_CANCELLED',
  VendorConfirmed = 'VENDOR_CONFIRMED',
  PreOrder = 'PRE_ORDER',
  Success = 'SUCCESS',
  PaymentFailed = 'PAYMENT_FAILED',
  Failed = 'FAILED',
  OrderFailed = 'ORDER_FAILED',
  Shipped = 'SHIPPED',
  ReturnToOrigin = 'RETURN_TO_ORIGIN',
}

export const enum PENDING_STATUS_ICON {
  icon = 'https://kheti-cdn.agrevolution.in/festive_greeting/Animation_pending.gif',
}
export const ORDERS_FETCH_LIMIT = 20

export enum TimelineLabelStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  ONGOING = 'ONGOING',
  CANCELLED = 'CANCELLED',
}
