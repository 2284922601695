import axios from 'axios'

import AuthToken, { APP_CODE_TYPE } from '@/models/Auth'
import URL from '@/utils/axios/url'

import getClientCode from '../helpers/getClientCode'

const validateOtp = async (
  mobileNumber: string,
  otp: string,
  appCode?: boolean | APP_CODE_TYPE
) => {
  const clientCode = getClientCode(appCode)
  const formData = new URLSearchParams()
  formData.append('mobile_number', mobileNumber)
  formData.append('otp', otp)
  formData.append('grant_type', 'password')
  formData.append('client_id', clientCode as string)
  if (clientCode === process.env.NEXT_PUBLIC_KEY_CLOAK_APP_CLIENT_ID) {
    formData.append('option', 'o')
  }
  return (
    await axios.post<AuthToken>(
      URL.KEYCLOAK_PROXY_TOKEN_API,
      formData.toString()
    )
  ).data
}

export default validateOtp
