import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BOOKING_MANDATORY_DATA, BOOKING_OPTIONAL_DATA, SERVICE_CART, SERVICE_ICON_URL, SERVICE_ID, SERVICE_NAME, SERVICE_VENDOR_ID, SERVICE_VENDOR_NAME } from '@/constants/common';
import { BookingDetail, BookingItem, OptionalFeildData, ServiceCart } from '@/models/farmerServices/common';
import { FormField } from '@/utils/api/getServiceFormComponent';
import { createUUID, removeLocalStorageKey, setLocalStorageKey } from '@/utils/helper';
import { RootState } from '../index';
interface ServiceSlice {
  serviceName?: string;
  serviceId?: string;
  serviceIconUrl?: string;
  sericeVendorId?: string;
  sericeVendorName?: string;
  serviceCart: ServiceCart;
  mandatoryFieldData: FormField[];
  optionalFieldData?: OptionalFeildData[] | BookingItem[] | null;
  bookingDetail?: BookingDetail | null;
}
const initialState: ServiceSlice = {
  serviceCart: {
    serviceId: '',
    cartId: createUUID(),
    bookingItem: null,
    vendorId: '',
    vendorName: ''
  },
  mandatoryFieldData: [],
  optionalFieldData: [],
  bookingDetail: null
};
const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceName: (state, action: PayloadAction<string>) => {
      setLocalStorageKey(SERVICE_NAME, action.payload);
      state.serviceName = action.payload;
    },
    setServiceId: (state, action: PayloadAction<string>) => {
      setLocalStorageKey(SERVICE_ID, action.payload);
      state.serviceId = action.payload;
    },
    setServiceIconUrl: (state, action: PayloadAction<string>) => {
      setLocalStorageKey(SERVICE_ICON_URL, action.payload);
      state.serviceIconUrl = action.payload;
    },
    setServiceVendorId: (state, action: PayloadAction<string>) => {
      setLocalStorageKey(SERVICE_VENDOR_ID, action.payload);
      state.sericeVendorId = action.payload;
      if (state.serviceCart.vendorId === '' && !state.serviceCart.bookingItem) state.serviceCart.vendorId = action.payload;
    },
    setServiceVendorName: (state, action: PayloadAction<string>) => {
      setLocalStorageKey(SERVICE_VENDOR_NAME, action.payload);
      state.sericeVendorName = action.payload;
      if (state.serviceCart.vendorName === '' && !state.serviceCart.bookingItem) state.serviceCart.vendorName = action.payload;
    },
    setServiceCart: (state, action: PayloadAction<ServiceCart>) => {
      setLocalStorageKey(SERVICE_CART, action.payload);
      state.serviceCart = action.payload;
    },
    setPackageQuantity: (state, action: PayloadAction<number>) => {
      if (state.serviceCart.bookingItem) {
        const updatedCart = {
          ...state.serviceCart,
          bookingItem: {
            ...state.serviceCart.bookingItem,
            quantity: action.payload
          }
        };
        setLocalStorageKey(SERVICE_CART, updatedCart);
        state.serviceCart = updatedCart;
      }
    },
    setBookingItem: (state, action: PayloadAction<BookingItem | null>) => {
      const updatedCart = {
        ...state.serviceCart,
        bookingItem: action.payload
      };
      setLocalStorageKey(SERVICE_CART, updatedCart);
      state.serviceCart = updatedCart;
    },
    setCartVendorId: (state, action: PayloadAction<string>) => {
      const updatedCart = {
        ...state.serviceCart,
        vendorId: action.payload
      };
      setLocalStorageKey(SERVICE_CART, updatedCart);
      state.serviceCart = updatedCart;
    },
    setMandatoryFieldData: (state, action: PayloadAction<FormField[] | []>) => {
      setLocalStorageKey(BOOKING_MANDATORY_DATA, action.payload);
      state.mandatoryFieldData = action.payload;
    },
    setOptionalFieldData: (state, action: PayloadAction<OptionalFeildData[] | BookingItem[] | null>) => {
      setLocalStorageKey(BOOKING_OPTIONAL_DATA, action.payload);
      state.optionalFieldData = action.payload;
    },
    setBookingDetail: (state, action: PayloadAction<BookingDetail>) => {
      state.bookingDetail = action.payload;
    },
    resetBookingDetail: state => {
      state.bookingDetail = null;
      state.optionalFieldData = null;
    },
    resetCart: state => {
      state.serviceCart = {
        serviceId: '',
        cartId: createUUID(),
        bookingItem: null,
        vendorId: '',
        vendorName: ''
      };
      removeLocalStorageKey(SERVICE_CART);
    },
    updateServiceCartId: state => {
      state.serviceCart = {
        ...state.serviceCart,
        cartId: createUUID()
      };
    }
  }
});
export const {
  setServiceName,
  setServiceId,
  setServiceIconUrl,
  setServiceVendorId,
  setServiceVendorName,
  setServiceCart,
  setPackageQuantity,
  setBookingItem,
  setCartVendorId,
  setMandatoryFieldData,
  setOptionalFieldData,
  setBookingDetail,
  resetBookingDetail,
  resetCart,
  updateServiceCartId
} = serviceSlice.actions;
export const selectServiceName = (state: RootState) => state.service.serviceName;
export const selectServiceId = (state: RootState) => state.service.serviceId;
export const selectServiceIconUrl = (state: RootState) => state.service.serviceIconUrl;
export const selectServiceVendorId = (state: RootState) => state.service.sericeVendorId;
export const selectServiceVendorName = (state: RootState) => state.service.sericeVendorName;
export const selectServiceCart = (state: RootState) => state.service.serviceCart;
export const selectPackageQuantity = (state: RootState, packageId: string) => {
  const bookingItem = state.service.serviceCart?.bookingItem;
  if (!bookingItem) return 0;
  return bookingItem.packageId === packageId ? bookingItem.quantity : 0;
};
export const selectQuantity = (state: RootState) => {
  const bookingItem = state.service.serviceCart?.bookingItem;
  if (!bookingItem) return 0;
  return bookingItem.quantity;
};
export const selectCartVendorId = (state: RootState) => state.service.serviceCart?.vendorId;
export const selectCartBookingItem = (state: RootState) => state.service.serviceCart.bookingItem;
export const selectMandatoryFieldData = (state: RootState) => state.service.mandatoryFieldData;
export const selectOptionalFieldData = (state: RootState) => state.service.optionalFieldData;
export const selectBookingDetail = (state: RootState) => state.service.bookingDetail;
export default serviceSlice;