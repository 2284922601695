//*TODO: Add all missing events here by checking on RN-App [FSS-3205]
//*TODO: Batch events as per their use cases and then sort them alphabetically [FSS-3205]

enum CustomEventTypes {
  ADD_ADDRESS = 'AddAddress',
  ADD_NEW_ADDRESS_1 = 'AddressAddNewClicked',
  ADD_NEW_ADDRESS_2 = 'AddNewAddressClick',
  ADD_ONE_ITEM_TO_CART = 'PlusIconClick',
  ADD_TO_CART = 'AddToCartClick',
  ADDRESS_DELETE = 'AddressDelete',
  ADDRESS_EDIT = 'AddressEdit',
  ADDRESS_MORE = 'AddressThreeDots',
  APP_BACK_BUTTON = 'BackIconClick',
  ASK_EXPERT = 'AskExpertsClick',
  BLOG_PAGE_VIEW = 'BlogDetailsPageView',
  BOTTOM_NAV_BAR_CLICKED = 'BottomNavBarClicked',
  BUY_NOW = 'BuyNowClick',
  CALL_CUSTOMER_CARE = 'CallCustomerCare',
  CANCEL_ORDER = 'OrderCancelled',
  CART_ADDRESS_PROMPT_CANCEL = 'CartAddressPromptCancel',
  CART_ADDRESS_PROMPT_PROCEED = 'CartAddressPromptProceed',
  CART_RESET = 'CartReset',
  CHANGE_ADDRESS = 'ChangeAddress',
  CHECK_COUPON_OFFERS = 'CheckOffersCoupons',
  COMMUNITY_POST_SHARED = 'CommunityPostShared',
  CONFIRM_ADDRESS = 'ConfirmAddressClick',
  CONSULT_AGRI_DOCTOR_CLICK = 'ConsultAgriDoctorClick',
  CROP_ICON = 'CropIconClick',
  CROSS_ICON = 'CrossIconClick',
  DEHAAT_FEED_CLICK = 'DeHaatFeedClick',
  DEHAAT_FEED_SHARED = 'DeHaatFeedShared',
  DELETE_PRODUCT_FROM_CART = 'RemoveProductClick',
  FARM_REPORT_FEEDBACK_INTENT = 'FarmReportFeedbackIntent',
  FARM_REPORT_LEFT_TOOGLE = 'FarmHealthReportLeftToggle',
  FARM_REPORT_MEANING = 'FarmHealthReportMeaning',
  FARM_REPORT_RIGHT_TOOGLE = 'FarmHealthReportRightToggle',
  HAMBRUGER_MENU_SELECTED = 'HamburgerMenuOptionSelected',
  HAMBURGER_MENU_CLICK = 'HamburgerMenuClicked',
  HOME_BANNER = 'HomeBannerClicked',
  HOME_ICON_CLICKS = 'HomeIconClicks',
  HOME_PAGE_SCROLLED_TO_500PX = 'HomePageScrolledBy500px',
  HOME_PAGE_TAB = 'HomePageSection',
  INITIATE_CANCEL_ORDER = 'CancelOrderIntent',
  INITIATE_DISEASE_COMMUNICATION = 'ClickPhotoforDiseaseIntent',
  INSURANCE_CATEGORY = 'InsuranceCategoryClick',
  LANGUAGE_UPDATE = 'FarmerLanguageUpdate',
  LIKE_COMMUNITY_POST = 'CommunityPostLike',
  LIKE_DEHAAT_FEED = 'DeHaatFeedLike',
  LOCATION_PERMISSION_DENIED = 'LocationPermissionDenied',
  LOCATION_PERMISSION_GRANTED = 'LocationPermissionGranted',
  LOGIN_TRIGGERED = 'LoginTriggered',
  NEW_ADDRESS_SAVED = 'AddressAddNewSaved',
  NON_HYPERLOCAL_PLACE_ORDER_ENQUIRY = 'NonHyperlocalPlaceOrderEnquiry',
  NOTIFICATION_HARD_ASK_SHOWN = 'NotificationHardAskShown',
  NOTIFICATION_PERMISSION_DENIED = 'NotificationPermissionDenied',
  NOTIFICATION_PERMISSION_GRANTED = 'NotificationPermissionGranted',
  NOTIFICATION_PROMPT_CLOSED = 'NotificationPromptClosed',
  NOTIFICATION_TURN_ON_CLICKED = 'NotificationTurnOnClicked',
  ONBOARDING_LANGUAGE_SELECTION = 'LanguageSelectedOnboarding',
  OPEN_PRODUCT_LIST = 'OpenProductList',
  ORDER_CREATED = 'OrderCreated',
  ORDER_DETAILS_VIEW = 'OrderViewDetails',
  ORDER_HELP = 'NeedHelpClick',
  OTP_TRIGGERED = 'OTPTriggered',
  PRODUCT_AVAILABLE = 'ProductAvailable',
  PRODUCT_OUT_OF_STOCK = 'ProductOutOfStock',
  RATING_SUBMITTED = 'RatingSubmitted',
  READ_MORE = 'ReadMoreClick',
  REMOVE_INSURANCE_PROMPT_CANCEL = 'RemoveInsurancePromptCancel',
  REMOVE_INSURANCE_PROMPT_PROCEED = 'RemoveInsurancePromptProceed',
  REMOVE_ONE_ITEM_FROM_CART = 'MinusIconClick',
  SCROLLED_TO_500PX = 'Scrolledby500px',
  SEARCH_BY_VOICE = 'SearchByVoice',
  SEARCH_RESET = 'SearchCrossIcon',
  SELECT_ADDRESS = 'SelectAddress',
  SHOW_PRODUCT_INSURANCE_LIST = 'ShowProductInsuranceList',
  SHOWING_CART_ADDRESS_PROMPT = 'ShowCartAddressPrompt',
  TERM_SHEET_DOWNLOADED = 'TermSheetDownload',
  TERM_SHEET_PAGE_OPENED = 'VisitedTermSheets',
  TextToSpeechPaused = 'TextToSpeechPaused',
  TextToSpeechPlayed = 'TextToSpeechPlayed',
  TextToSpeechResumed = 'TextToSpeechResumed',
  VENDOR_CALL_SHOP = 'CallShopButton',
  VENDOR_CARD_RIGHT_SCROLL = 'RightScroll',
  VENDOR_DIRECTION = 'NavigateButton',
  VIEW_CROP_PROBLEM = 'CropProblemViewed',
  WHATSAPP_SHARE = 'WhatsappShare',
  PRODUCT_EDIT_PRICE_CLICKED = 'HyperlocalPriceEditCTAClicked',
  HYP_PRICED_EDITED = 'HyperlocalProductPriceEdited',
  VARIANT_SELECTION = 'VariantSelection',
  KNOW_MORE_CLICK = 'KnowMoreClick',
  ADD_TO_CART_INTENT = 'AddtoCartIntent',
  TOGGLE_DOWN_CLICK = 'ToggleDownClick',
  VIDEO_PLAY_CLICK = 'VideoPlayClick',
  SHORTS_PLAY_CLICK = 'ShortsPlayClick',
  GROWTH_USER_MOBILE_NUMBER = 'GrowthUserMobileNumber',
  GROWTH_USER_DETAILS = 'GrowthUserDetails',
  GROWTH_ORDER_DETAILS = 'GrowthOrderDetails',
  ADD_TO_CART_STARTER = 'AddToCart',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  GROWTH_PURCHASE = 'GrowthPurchase',
  GROWTH_OTP_SENT = 'GrowthOTPSent',
  MANDI_PRICE_DETAILS_CLICK = 'MandiPriceDetailsIntent',
  MANDI_WEATHER_ICON_CLICK = 'WeatherIconClick',
  MANDI_LIST_VIEW_MORE_CLICK = 'MandiHomeViewMoreClick',
  MANDI_SERVICES_CLICK = 'MandiRelatedServicesClick',
  MANDI_SUBMIT_RECIEPT_FORM_FIELDS = 'MandiServiceForm',
  MANDI_CROP_PHOTO_SUBMISSION_INTENT = 'MandiFasalPhotoSubmissionIntent',
  MANDI_CROP_PHOTO_ADDED = 'MandiFasalPhotoAdded',
  MANDI_RECIEPT_PHOTO_SUBMISSION_INTENT = 'MandiReceiptPhotoSubmissionIntent',
  MANDI_RECIEPT_PHOTO_ADDED = 'MandiReceiptPhotoAdded',
  MANDI_FORM_SUBMISSION = 'MandiServiceFormSubmission',
  SHRI_CARD_ACTIVATE_INTENT = 'ShriCardActivateIntent',
  SHRI_CARD_ACTIVATE_LOGIN_INTENT = 'ShriCardActivateLoginIntent',
  SHRI_CARD_APPLY_INTENT = 'ShriCardApplyIntent',
  SHRI_CARD_APPLY_LOGIN_INTENT = 'ShriCardApplyLoginIntent',
  APPLY_SHRI_CARD = 'ApplyShriCard',
  VALIDATE_SHRI_CARD_NUMBER = 'ValidateShriCardNumber',
  FETCH_LOCATION_INTENT = 'FetchLocationIntent',
  ADD_AND_SAVE_ADDRESS_INTENT = 'AddandSaveAddressIntent',
  ACTIVATE_SHRI_CARD_PROGRAM = 'ActivateShriCardProgramScreen',
  PRODUCT_BUY_INTENT_SHRI_CARD = 'ProductBuyIntentShriCard',
  SHRI_CARD_NOT_AVAILABLE = 'ShriCardNotAvailableAtLocation',
  VARIANT_SELECTION_FOR_CART = 'VariantSelectionForCart',
  OPEN_PRODUCT_DETAIL = 'OpenProductDetail',
  PRODUCT_DETAILS_VARIANT_SELECT = 'VariantSelected',
  PRODUCT_FILTER_CHIP_REMOVED = 'ProductFilterChipRemoved',
  PRODUCT_FILTER_CLICK = 'ProductFilterClick',
  ORDER_ISSUE_SUBCATEGORY_SELECT = 'RaiseIssueSubCategorySelection',
  ORDER_ISSUE_RAISE_ISSUE_CLICK = 'RaiseIssueClick',
  ORDER_ISSUE_IMAGE_SELECTION = 'RaiseIssueImageSelection',
  ORDER_DETAILS_ISSUES_LIST_ITEM_CLICK = 'CheckIssue',
  ORDER_ISSUE_CHECKBOX_CLICK = 'CheckboxClick',
  ORDER_ISSUE_PLUS_MINUS_CLICK = 'GrievancePlusOrMinusClick',
  ORDER_ISSUE_STEP1_CLICK = 'RaiseIssueStep1',
  PRODUCT_DETAILS_DELIVERY_ICON_CLICK = 'DeliveryIconClicks',
  THREE_DOTS_CLICK = 'ThreeDotsClick',
  ORDER_DETAILS_GET_STATUS_CLICK = 'GetOrderStatus',
  ORDER_DETAILS_NEED_HELP_CLICK = 'NeedHelpInOrderClick',
  ORDER_DETAILS_DOWNLOAD_INVOICE_CLICK = 'ViewOrDownloadInvoiceIntent',
  ORDER_DETAILS_RETURN_INTENT = 'OrderReturnIntent',
  ORDER_RETURN_REASON_SELECT_INTENT = 'OrderReturnReasonSelectIntent',
  ORDER_RETURN_REASON_SELECT = 'OrderReturnReasonSelection',
  ORDER_RETURN_IMAGE_SELECTION = 'ReturnFlowImageSelection',
  ORDER_RETURN_CONFIRMATION_CLICK = 'ReturnOrderConfirmation',
  DELETE_USER_ACCOUNT = 'DeleteUserAccount',
  SATELLITE_SEND_SELFIE_BUTTON_CLICKED = 'Satellite Send Selfie Button Clicked',
  SATELLITE_ADD_FARM_REACHED = 'Satellite Add Farm Reached',
  SATELLITE_CLICK_SELFIE_LATER = 'Satellite Click Selfie Later',
  SATELLITE_SELFIE_CLICKED = 'Satellite Selfie Clicked',
  SATELLITE_FARM_NAME_ADDED = 'Satellite Farm Name Entered',
  SATELLITE_CROP_ADDED = 'Crop Selected',
  SATELLITE_SHARE_CLICKED = 'Satellite Share Clicked',
  SATELLITE_GO_TO_HOME_CLICKED = 'Satellite Go To Home Clicked',
  SATELLITE_FARM_FEEDBACK_ADDED = 'Feedback Added',
  SATELLITE_CHANGE_CROP = 'Satellite Change Crop Intent',
  FARM_REPORT_DELAY_INFO_CHECK = 'FarmReportDelayInformationCheck',
  SATELLITE_REPORT_VIEW = 'Satellite Report View',
  SATELLITE_DELETE_CROP = 'Satellite Farm Deleted',
  SATELLITE_ADD_FARM_INTEREST = 'Satellite Add Farm Interest',
  SERVICE_LANDING_PAGE_VISITED = 'Service Landing Page Visited',
  SERVICE_BOOKING_INITIATED = 'Service Booking Initiated',
  SERVICE_PACKAGE_SELECTED = 'Service Package Selected',
  SERVICE_MANDATORY_FIELD_ADDED = 'Service Mandatory Field Added',
  SERVICE_MANDATORY_FIELDS_SUBMITTED = 'Service Mandatory Fields Submitted',
  SERVICE_ADDRESS_ADDED = 'Service Address Added',
  SERVICE_USER_CLICKS_TO_BOOK_NOW = 'Service User Clicks to Book Now',
  SERVICE_USER_SELECTS_PAYMENT_METHOD = 'Service User Selects Payment Method',
}

export default CustomEventTypes
